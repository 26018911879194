import http from './interface'
import { UserModule } from '@/store/modules/user'
import { IPackageListQuery, IPackageSortReqModel, IPackageReqModel } from '@/pages/package/data/packageModel'
import qs from 'qs';

export const getPackageCountByStatus = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/package-introductions/count`)
}

export const getPackageRecords = (data: IPackageListQuery) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/package-introductions?${qs.stringify(data)}`
  return http.get(url)
}

// 取消发布和发布
export const updatePackagePublishStatus = (
  packageId: string,
  whetherPublish: boolean,
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/package-introductions/${packageId}/publish`,
    {
      whetherPublish,
    }
  )
}

// 删除
export const deletePackage = (packageId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/package-introductions/${packageId}`)
}

// 详情
export const getPackageDetails = (packageId: string) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/package-introductions/detail/${packageId}`)
}

// 新增
export const addPackage = (data: IPackageReqModel) => {
  const { image, ...rest } = data;
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/package-introductions`, {
    ...rest,
    imageId: image[0].id
  })
}

// 修改
export const updatePackage = (packageId: string, data: IPackageReqModel) => {
  const { image, ...rest } = data;
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/package-introductions/${packageId}`, {
    ...rest,
    imageId: image[0].id
  })
}

// 更新package展示顺序
export const updatePackageListSort = (list: IPackageSortReqModel[]) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/package-introductions/sort`, list)
}