import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { IPackageCountRes } from '@/pages/package/data/packageModel'
import { getPackageCountByStatus } from '@/remote/package'

export interface IPackageState {
  PackageCountRes: IPackageCountRes
}

@Module({ dynamic: true, store, name: 'Package' })
class Package extends VuexModule implements IPackageState {
  public PackageCountRes = {
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0
  }

  @Action
  public async FetchPackageCountByStatus() {
    try {
      const res = await getPackageCountByStatus()
      if (res && res.data && res.data.data) {
        this.SET_PACKAGE_COUNT_RES(res.data.data)
      }
    } catch {
      console.log('getPackageCountByStatus出错了')
    }
  }

  @Mutation
   SET_PACKAGE_COUNT_RES(res: IPackageCountRes) {
    this.PackageCountRes = res
  }
}

export const PackageModule = getModule(Package)
