






































import { Component, Vue } from "vue-property-decorator";
import PanelGroup from "@/components/PanelGroup.vue";
import PackageTable from "./components/PackageTable.vue";
import PackageEdit from "./components/PackageEdit.vue";
import { ITabModel, IPanelData } from "@/data/commonModel";
import { IPackageModel } from "./data/packageModel";
import { PackageModule } from "@/store/modules/package";

@Component({
  components: {
    PanelGroup,
    PackageTable,
    PackageEdit,
  },
})
export default class extends Vue {
  editDialogVisible: boolean = false;
  isOrdering: boolean = false; // 是否在排序中
  editType: string = "add";
  panelObj: IPanelData = { type: "all", searchText: "" ,show:true};
  packageModel: IPackageModel | null = null;

  get tabs(): ITabModel[] {
    const arr = [
      {
        title: `全部(${PackageModule.PackageCountRes.allCount})`,
        key: "all",
      },
      {
        title: `已发布(${PackageModule.PackageCountRes.publishedCount})`,
        key: "published",
      },
      {
        title: `未发布(${PackageModule.PackageCountRes.unpublishedCount})`,
        key: "unpublished",
      },
    ];
    return arr;
  }

  // Watch
  // @Watch("panelObj.searchText")
  // onSearchTextChange() {
  //   PackageModule.FetchPackageCountByStatus();
  // }

  mounted() {
    PackageModule.FetchPackageCountByStatus();
  }

  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
    this.isOrdering = false;
  }

  _onClickAdd() {
    this.editType = "add";
    this.packageModel = null;
    this.editDialogVisible = true;
  }

  // 开始/取消排序
  _onClickSort() {
    this.isOrdering = !this.isOrdering;
  }

  _handleEditPackageSuccess(type: string) {
    PackageModule.FetchPackageCountByStatus();
    const ref: PackageTable = this.$refs["package-table"] as PackageTable;
    ref.refreshTable();
  }

  _handleEditPackage(value: IPackageModel) {
    this.editType = "edit";
    this.editDialogVisible = true;
    this.packageModel = JSON.parse(JSON.stringify(value));
  }
}
